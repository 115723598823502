<template>
  <div class="card">
    <DataTable :value="localReceivedItems" dataKey="id">
      <Column field="quantity_received" :styles="{ width: '30%' }" header="Qty">
        <template #body="slotProps">
          <InputText v-if="editableRowId === slotProps.data.id" v-model="slotProps.data.quantity_received"/>
          <span v-else>{{ slotProps.data.quantity_received }}</span>
        </template>
      </Column>

      <Column field="received_date" :styles="{ width: '50%' }" header="Received Date">
        <template #body="slotProps">
          <Calendar v-if="editableRowId === slotProps.data.id" appendTo="body"
                    dateFormat="dd/mm/yy"
                    v-model="slotProps.data.received_date" :showIcon="true"/>
          <span v-else>{{ formatDateUK(slotProps.data.received_date) }}</span>
        </template>
      </Column>

      <Column :styles="{ width: '20%' }">
        <template #body="slotProps">
          <div class="flex justify-end items-center">
            <AppButton v-if="editableRowId === slotProps.data.id" color="blue" label="" :icon="true"
                       class="mr-1" @click="saveRow(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="save"/>
              </template>
            </AppButton>
            <AppButton v-if="editableRowId === slotProps.data.id && localReceivedItems.length !== 0" color="gray"
                       label=""
                       :icon="true" class="mr-1" @click="cancelEdit(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="close"/>
              </template>
            </AppButton>
            <AppButton v-else color="blue" label="" :icon="true" class="mr-1"
                       @click="editRow(slotProps.data.id)">
              <template v-slot:icon>
                <Icon iconType="edit"/>
              </template>
            </AppButton>
            <AppButton color="red" label="" :icon="true" class="mr-1" @click="deleteRow(slotProps.data.id)">
              <template v-slot:icon>
                <Icon iconType="delete"/>
              </template>
            </AppButton>
            <AppButton color="green" v-if="isLastRow(slotProps.data.id)" label="" :icon="true" class="mr-1"
                       @click="addReceivedItem">
              <template v-slot:icon>
                <Icon iconType="add-notes"/>
              </template>
            </AppButton>
          </div>
        </template>
      </Column>
    </DataTable>

    <div class="flex justify-between items-center mt-4">
      <span class="font-semibold">Total Received:</span>
      <span>{{ totalReceived }}</span>
    </div>
    <div class="flex justify-between items-center mt-2">
      <span class="font-semibold">Pending Quantity:</span>
      <span>{{ pendingQuantity }}</span>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import moment from 'moment';
import _ from 'lodash'; // lodash for deep copy

export default {
  mixins: [notificationMixin],
  components: {Icon, AppButton},
  props: {
    initialReceivedItems: {
      type: Array,
      required: true,
      default: [],
    },
    targetQuantity: {
      type: Number,
      required: true,
    },
    purchase_order_id: {
      type: String,
      required: true,
    },
    purchase_item_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localReceivedItems: [],
      editableRowId: null,
      originalReceivedItemData: {}, // Store original data for rollback
    };
  },

  mounted() {
    this.localReceivedItems = _.cloneDeep(this.initialReceivedItems); // Deep copy initial items
    this.checkAndAddInitialReceivedItem();
  },
  computed: {
    totalReceived() {
      return this.localReceivedItems.reduce((sum, item) => sum + parseFloat(item.quantity_received || 0), 0);
    },
    pendingQuantity() {
      return this.targetQuantity - this.totalReceived;
    }
  },
  methods: {
    getCurrentReceivedItems() {
      return this.localReceivedItems;
    },
    checkAndAddInitialReceivedItem() {
      if (this.localReceivedItems.length === 0) {
        this.addReceivedItem();
      }
    },
    addReceivedItem() {
      if (this.editableRowId != null) {
        this.notifyWarning("Please save the current item before adding a new one.");
        return;
      }

      const newReceivedItem = {id: this.generateGUID(), quantity_received: '', received_date: '', isNew: true};
      this.localReceivedItems.push(newReceivedItem);
      this.editableRowId = newReceivedItem.id;
    },
    editRow(rowId) {
      const item = this.localReceivedItems.find(item => item.id === rowId);
      if (item) {
        this.originalReceivedItemData[rowId] = _.cloneDeep(item);
      }
      this.editableRowId = rowId;
    },
    async saveRow(item) {
      // Save current state in case of rollback
      if (!this.originalReceivedItemData[item.id]) {
        this.originalReceivedItemData[item.id] = _.cloneDeep(item);
      }

      if (!item.quantity_received || !item.received_date) {
        this.notifyError("The received quantity and date cannot be empty.");
        return;
      }

      const totalReceived = this.localReceivedItems.reduce((sum, currentItem) => {
        if (currentItem.id !== item.id) {
          return sum + parseFloat(currentItem.quantity_received || 0);
        }
        return sum;
      }, 0);

      const newTotalReceived = totalReceived + parseFloat(item.quantity_received);

      if (newTotalReceived > this.targetQuantity) {
        this.notifyWarning("The total received quantity exceeds the target quantity.");
        return;
      }

      const index = this.localReceivedItems.findIndex(i => i.id === item.id);
      if (index !== -1) {
        this.localReceivedItems[index] = {...item, isNew: false};
      }
      this.editableRowId = null;

      const saveResult = await this.saveReceivedItemToServer(item);
      if (saveResult) {
        this.emitLocalReceivedItems();
        delete this.originalReceivedItemData[item.id]; // Clear the backup on success
      } else {
        // Rollback to previous state if save failed
        this.localReceivedItems[index] = this.originalReceivedItemData[item.id];
        this.editableRowId = item.id;
      }
    },

    convertDateToUnix(date) {
      if (date instanceof Date) {
        return Math.floor(date.getTime() / 1000);
      } else if (typeof date === 'string') {
        const momentDate = this.$moment(date, 'DD-MM-YYYY', true);
        if (momentDate.isValid()) {
          return momentDate.unix();
        } else {
          const isoMomentDate = this.$moment(date, this.$moment.ISO_8601);
          if (isoMomentDate.isValid()) {
            return isoMomentDate.unix();
          }
        }
      }
      console.error('Invalid or unsupported date format:', date);
      return null;
    },

    async saveReceivedItemToServer(item) {
      const itemCopy = _.cloneDeep(item);
      itemCopy.received_date = this.convertDateToUnix(itemCopy.received_date);

      try {
        if (itemCopy.isNew) {
          const createdItem = await this.PurchaseOrderService.createReceivedItem(
              this.purchase_order_id,
              this.purchase_item_id,
              itemCopy
          );
          const index = this.localReceivedItems.findIndex(i => i.id === item.id);

          if (index !== -1 && createdItem) {
            this.localReceivedItems[index].received_item_id = createdItem.received_item_id;
            this.localReceivedItems[index].isNew = false;
          }

        } else {
          await this.PurchaseOrderService.updateReceivedItem(
              this.purchase_order_id,
              this.purchase_item_id,
              itemCopy.received_item_id,
              itemCopy
          );
        }
        this.notifySuccess(`Received item ${itemCopy.isNew ? 'created' : 'updated'} successfully.`);
        item.isNew = false;
        return true;
      } catch (error) {
        this.notifyError(`Error ${itemCopy.isNew ? 'creating' : 'updating'} received item.`);
        console.error(error);
        return false;
      }
    },

    async deleteRow(rowId) {
      // Find the index and the item to be deleted
      const itemIndex = this.localReceivedItems.findIndex(i => i.id === rowId);
      const item = this.localReceivedItems[itemIndex];

      // Store a backup of the current state before attempting deletion
      const backupReceivedItems = _.cloneDeep(this.localReceivedItems);

      if (item && !item.isNew) {
        try {
          // Attempt to delete the item from the server
          await this.PurchaseOrderService.deleteReceivedItem(
              this.purchase_order_id,
              this.purchase_item_id,
              item.received_item_id
          );
          // If successful, remove the item from the local state
          this.localReceivedItems.splice(itemIndex, 1);
          this.notifySuccess("Received item deleted successfully.");
        } catch (error) {
          // If there is an error deleting from the server
          this.notifyError("Error deleting received item.");
          console.error(error);
          // Rollback to the previous state if delete failed
          this.localReceivedItems = backupReceivedItems;
        }
      } else {
        // If the item is new (i.e., it hasn't been saved to the server yet)
        this.localReceivedItems.splice(itemIndex, 1);
      }

      this.editableRowId = null;

      this.$nextTick(() => {
        this.checkAndAddInitialReceivedItem();
        this.emitLocalReceivedItems();
      });
    },


    cancelEdit(item) {
      if (item.isNew) {
        this.localReceivedItems = this.localReceivedItems.filter(i => i.id !== item.id);
      } else {
        const originalData = this.originalReceivedItemData[item.id];
        if (originalData) {
          const index = this.localReceivedItems.findIndex(p => p.id === item.id);
          if (index !== -1) {
            this.localReceivedItems.splice(index, 1, originalData);
          }
        }
      }
      this.editableRowId = null;
      delete this.originalReceivedItemData[item.id];
    },
    isLastRow(rowId) {
      return this.localReceivedItems[this.localReceivedItems.length - 1].id === rowId;
    },
    generateGUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    formatDateUK(date) {
      return (typeof date === 'string') ? date : this.$moment(date).format('DD-MM-YYYY');
    },
    emitLocalReceivedItems() {
      this.$emit('receivedItems', this.localReceivedItems);
    }
  },
  watch: {
    initialReceivedItems(newItems) {
      this.localReceivedItems = _.cloneDeep(newItems); // Deep copy initial items
      this.editableRowId = null;
    },
  },
};
</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}
</style>
