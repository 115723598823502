<template>
  <div class="p-4 bg-white rounded-lg">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-semibold">Email Purchase Order - {{ purchaseOrderNumber }}</h2>
    </div>

    <div class="mb-4">
      <label class="block uppercase font-semibold text-sm">Email Address</label>
      <input class="bge-input bge-input-spacing rounded w-full" v-model="recipientEmail" type="email"
             placeholder="Enter recipient email address"/>
    </div>

    <div class="mb-4">
      <label class="block uppercase font-semibold text-sm">Subject</label>
      <input class="bge-input bge-input-spacing rounded w-full" v-model="emailSubject" type="text"
      />
    </div>

    <div class="mb-4">
      <label class="block uppercase font-semibold text-sm">Email Text</label>
      <StyledTextEditor v-model="emailMessage" :options="editorOptions"
      />
    </div>

    <div class="mb-4">
      <label class="block uppercase font-semibold text-sm">Send Copy To (Sent as separate email)</label>
      <Chips v-model="ccEmail" class="w-full" separator=","/>
    </div>

    <div class="mb-4 flex items-center">
      <Checkbox v-model="hasAttachments" :binary="true" class="mr-2"/>
      <label class="block uppercase font-semibold text-sm">Include 'PO' Attachment</label>
    </div>

    <div class="flex justify-end space-x-2">
      <AppButton color="gray" @click="setAsDefault" label="Set as Default"></AppButton>
      <AppButton color="blue" @click="sendOrder" label="Send Order">
        <template v-slot:icon>
          <Icon iconType="send"/>
        </template>
      </AppButton>
    </div>

    <div class="mt-10">
      <EmailQueueTable ref="emailQueueTable" :referenceId="this.purchaseOrderId"></EmailQueueTable>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import StyledTextEditor from "@/components/StyledTextEditor.vue";
import notificationMixin from "@/mixins/notificationMixin";
import EmailQueueTable from "@/views/HR/Purchases/Components/EmailQueueTable.vue";

export default {
  name: "EmailSend",
  mixins: [notificationMixin],
  components: {
    Icon,
    AppButton,
    EmailQueueTable,
    StyledTextEditor
  },
  props: {
    purchaseOrderId: {
      type: String,
      required: true,
    },
    purchaseOrderNumber: {
      type: String,
      required: true,
    },
    supplier: {
      type: Object,
      required: true,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      recipientEmail: '',
      emailSubject: '',
      emailMessage: '',
      ccEmail: null,
      defaultSubject: '',
      defaultEmailText: '',
      hasAttachments: true,
      attachmentUrls: '',
      editorOptions: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
        ]
      },
    };
  },
  mounted() {
    this.fetchEmailTemplate();
    this.recipientEmail = this.supplier.people.email;
  },
  methods: {
    replacePlaceholders(template, data) {
      return template.replace(/{{\s*(\w+)\s*}}/g, (match, p1) => data[p1] || '');
    },
    async fetchEmailTemplate() {
      try {
        const template = await this.EmailService.getTemplateById('emt_fd88e0261507407c95e23d86b944a6ab');
        this.defaultSubject = this.replacePlaceholders(template.subject || '', {
          orderNumber: this.purchaseOrderNumber
        });
        this.defaultEmailText = this.replacePlaceholders(template.body || '', {
          supplier: `${this.supplier.people.firstname || ''} ${this.supplier.people.lastname || ''}`,
          orderNumber: this.purchaseOrderNumber,
          requestedDate: this.purchaseOrder.requested_delivery_date || ''
        });


        this.emailSubject = this.defaultSubject;
        this.emailMessage = this.defaultEmailText;

      } catch (error) {
        console.error('Error fetching email template:', error);
      }
    },
    setAsDefault() {
      this.emailSubject = this.defaultSubject;
      this.emailMessage = this.defaultEmailText;
    },
    async sendOrder() {
      const emailData = {
        template_key: 'purchase_order_email_temp',
        reference_id: this.purchaseOrderId,
        to: [
          {
            name: `${this.supplier.people.firstname || 'Hi'} ${this.supplier.people.lastname || ''}`,
            email: this.recipientEmail
          }
        ],
        cc: this.ccEmail ? this.ccEmail : [],
        bcc: [],
        status: "PENDING",
        category: "notification",
        data: {
          supplier: this.supplier.people.name,
          orderNumber: this.purchaseOrderNumber,
          requestedDate: "2024-06-25",
        },
        use_custom_subject_body: true,
        custom_subject: this.emailSubject,
        custom_body: this.emailMessage,
        has_attachments: this.hasAttachments,
        attachments: this.hasAttachments ? [
          {
            url: `purchase-orders/${this.purchaseOrderId}/report/purchase-order`,
            file_name: `${this.purchaseOrderNumber}-purchase-order.pdf`
          }
        ] : []
      };

      try {
        await this.EmailService.createQueueItem(emailData);
        this.notifySuccess('Email sent successfully', 'success');
        await this.$refs.emailQueueTable.loadEmailQueues();
      } catch (error) {
        console.error('Error sending email:', error);
        this.notifyError('Failed to send email', 'error');
      }
    }
  },
};
</script>

<style scoped>
/* No additional styles required */
</style>
