<template>
  <div class="signature-container flex flex-col items-center w-full p-4">
    <canvas ref="signatureCanvas" v-if="!existingSignature"
            class="signature-canvas w-full max-w-lg h-52 border border-gray-300" @mousedown="startDrawing"
            @mouseup="stopDrawing" @mousemove="draw"></canvas>
    <div class="buttons mt-4 flex space-x-2" v-if="!existingSignature">
      <AppButton color="gray" label="Clear" :icon="true" @click="clearSignature">
        <template v-slot:icon>
          <Icon iconType="close"/>
        </template>
      </AppButton>
      <AppButton color="green" label="Upload" @click="uploadSignature" :icon="true" badgeType="success">
        <template v-slot:icon>
          <Icon iconType="upload" class="mr-2"/>
        </template>
      </AppButton>
    </div>
    <img v-if="existingSignature" :src="existingSignature" alt="Signature" class="signature-img mt-4 w-full max-w-lg"/>
  </div>
</template>
<script>
import SignaturePad from 'signature_pad';
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: {Icon, AppButton},

  props: {
    uploadRefId: {
      type: String,
      required: true,
    },
    uploadSignatureF: {
      type: Function,
      required: true,
    },
    getSignatureF: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      signaturePad: null,
      existingSignature: null,
      drawing: false,
      context: null,
    };
  },

  async mounted() {
    this.signaturePad = new SignaturePad(this.$refs.signatureCanvas, {
      backgroundColor: 'rgb(255, 255, 255)',
    });
    await this.loadFiles();

    this.setCanvasSize();
    window.addEventListener('resize', this.setCanvasSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setCanvasSize);
  },

  methods: {
    setCanvasSize() {
      const canvas = this.$refs.signatureCanvas;
      if (canvas) {
        // Save current content
        const dataUrl = canvas.toDataURL();

        // Set the canvas dimensions to match the container
        const rect = canvas.getBoundingClientRect();
        canvas.width = rect.width;
        canvas.height = rect.height;

        // Restore the content
        const img = new Image();
        img.src = dataUrl;
        img.onload = () => {
          const context = canvas.getContext('2d');
          context.fillStyle = 'rgb(255, 255, 255)'; // Ensure the background color remains white
          context.fillRect(0, 0, canvas.width, canvas.height);
          context.drawImage(img, 0, 0);
        };
      }
    },
    startDrawing(event) {
      this.drawing = true;
      this.context = this.$refs.signatureCanvas.getContext('2d');
      this.context.beginPath();
      this.context.moveTo(this.getMousePos(event).x, this.getMousePos(event).y);
    },
    stopDrawing() {
      this.drawing = false;
    },
    draw(event) {
      if (!this.drawing) return;
      const pos = this.getMousePos(event);
      this.context.lineTo(pos.x, pos.y);
      this.context.stroke();
    },
    getMousePos(event) {
      const rect = this.$refs.signatureCanvas.getBoundingClientRect();
      return {
        x: (event.clientX - rect.left) * (this.$refs.signatureCanvas.width / rect.width),
        y: (event.clientY - rect.top) * (this.$refs.signatureCanvas.height / rect.height)
      };
    },
    clearSignature() {
      this.signaturePad.clear();
      this.existingSignature = null;
    },

    async uploadSignature() {
      if (!this.uploadRefId) {
        this.notifyWarning("Cannot add new files before saving this item.");
        return;
      }

      if (this.signaturePad.isEmpty()) {
        this.notifyError('Please provide a signature before uploading.');
        return;
      }

      try {
        const signatureImage = this.signaturePad.toDataURL('image/png');
        const blob = await (await fetch(signatureImage)).blob();
        const file = new File([blob], 'signature.png', {type: 'image/png'});

        const fileToUpload = [{
          name: file.name,
          content_type: file.type,
          visibility: "Internal",
          category: "signatures",
          unique_id: this.generateUniqueId(),
          requires_thumbnail: false,
          document_role: 'signature',
        }];


        const uploadResults = await this.uploadSignatureF(fileToUpload, this.uploadRefId);

        if (uploadResults && uploadResults.length > 0) {
          const firstUploadResult = uploadResults[0];
          if (firstUploadResult.access_url) {
            await this.uploadFileToBlob(firstUploadResult.access_url, file);
            this.notifySuccess("Signature uploaded successfully.");
            await this.loadFiles();
          } else {
            this.notifyError("Upload failed: No access URL returned for the first result.");
          }
        } else {
          this.notifyError("Upload failed: No results returned.");
        }
      } catch (error) {
        console.error("Upload error:", error);
        this.notifyError("There was an error uploading the signature.");
      }
    },
    generateUniqueId() {
      return `id_${Date.now()}_${Math.random().toString(36).slice(2, 11)}`;
    },
    async loadFiles() {
      try {
        if (!this.uploadRefId) return;
        const files = await this.getSignatureF(this.uploadRefId, 'signature');
        if (files.length > 0) {
          this.existingSignature = files[0].access_url;
        }
      } catch (error) {
        console.error("Error loading files:", error);
      }
    },
    async uploadFileToBlob(sasUrl, file) {
      const url = new URL(sasUrl);
      const accountUrl = url.origin;
      const sasToken = url.search;
      const pathParts = url.pathname.split("/").filter(p => p.trim() !== "");
      const containerName = pathParts[0];
      const blobName = pathParts.slice(1).join("/");
      const blobService = AzureStorage.Blob.createBlobServiceWithSas(accountUrl, sasToken);

      return new Promise((resolve, reject) => {
        blobService.createBlockBlobFromBrowserFile(containerName, blobName, file, {blockSize: file.size}, (error, result) => {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        });
      });
    },
  },
};
</script>


<style scoped>
.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.signature-canvas {
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

button {
  padding: 5px 10px;
  cursor: pointer;
}

</style>
