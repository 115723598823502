<template>
  <div class="card">

    <DataTable :value="localNotes" dataKey="id" class="hide-header">
      <Column field="text" :styles="{ width: '80%' }">
        <template #body="slotProps">
          <InputText v-if="editableRowId === slotProps.data.pre_text_id" v-model="slotProps.data.text"/>
          <span v-else>{{ slotProps.data.text }}</span>
        </template>
      </Column>

      <Column :styles="{ width: '20%' }">
        <template #body="slotProps">
          <div class="flex justify-end items-center">
            <AppButton v-if="editableRowId === slotProps.data.pre_text_id" color="blue" label=""
                       :icon=true
                       class="mr-1"
                       @click="saveRow(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="save"/>
              </template>
            </AppButton>
            <AppButton v-if="editableRowId === slotProps.data.pre_text_id && localNotes.length !== 0" color="gray"
                       label=""
                       :icon=true
                       class="mr-1"
                       @click="cancelEdit(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="close"/>
              </template>
            </AppButton>
            <AppButton v-else color="blue" label=""
                       :icon=true
                       class="mr-1"
                       @click="editRow(slotProps.data.pre_text_id)">
              <template v-slot:icon>
                <Icon iconType="edit"/>
              </template>
            </AppButton>
            <AppButton color="red" label=""
                       :icon=true
                       class="mr-1"
                       @click="deleteRow(slotProps.data.pre_text_id)">
              <template v-slot:icon>
                <Icon iconType="delete"/>
              </template>
            </AppButton>
            <AppButton color="green" v-if="isLastRow(slotProps.data.pre_text_id)" label=""
                       :icon=true
                       class="mr-1"
                       @click="addNote">
              <template v-slot:icon>
                <Icon iconType="add-notes"/>
              </template>
            </AppButton>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: {Icon, AppButton},
  props: {
    initialNotes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localNotes: [],
      editableRowId: null,
    };
  },

  mounted() {
    this.localNotes = this.initialNotes.slice();
    this.checkAndAddInitialNote();
  },
  methods: {
    getCurrentNotes() {
      return this.localNotes;
    },
    addItems(itemsToAdd) {
      let newNotes = [];
      let duplicateFound = false;

      itemsToAdd.forEach(item => {
        if (!this.localNotes.find(note => note.pre_text_id === item.pre_text_id)) {
          newNotes.push({...item, isNew: false});
        } else {
          duplicateFound = true;
        }
      });

      this.localNotes = [...newNotes, ...this.localNotes];

      if (duplicateFound) {
        this.notifyWarning('Some items were already added.');
      }

      this.$emit('items-added');
    },
    checkAndAddInitialNote() {
      if (this.localNotes.length === 0) {
        this.addNote();
      }
    },
    addNote() {
      if (this.editableRowId != null) {
        this.notifyWarning("Please save the current note before adding a new one.");
        return;
      }

      const newNote = {pre_text_id: this.generateGUID(), text: '', isNew: true};
      this.localNotes.push(newNote);
      this.editableRowId = newNote.pre_text_id;
    },
    editRow(rowId) {
      if (this.editableRowId !== null) {
        const currentIndex = this.localNotes.findIndex(note => note.pre_text_id === this.editableRowId);
        if (currentIndex !== -1 && !this.localNotes[currentIndex].text.trim()) {
          this.localNotes.splice(currentIndex, 1);
        }
      }
      this.editableRowId = rowId;
    },
    saveRow(note) {
      if (!note.text.trim()) {
        this.notifyError("The note text cannot be empty.");
        return;
      }

      const index = this.localNotes.findIndex(n => n.pre_text_id === note.pre_text_id);
      if (index !== -1) {
        this.localNotes[index] = {...note, isNew: false};
      }
      this.editableRowId = null;
    },
    cancelEdit(note) {
      if (note.isNew) {
        this.localNotes = this.localNotes.filter(n => n.pre_text_id !== note.pre_text_id);
      }
      this.editableRowId = null;

      this.$nextTick(() => {
        this.checkAndAddInitialNote();
      });
    },
    deleteRow(rowId) {
      this.localNotes = this.localNotes.filter(n => n.pre_text_id !== rowId);
      this.editableRowId = null;

      this.$nextTick(() => {
        this.checkAndAddInitialNote();
      });
    },
    isLastRow(rowId) {
      return this.localNotes[this.localNotes.length - 1].pre_text_id === rowId;
    },
    generateGUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
  },
  watch: {
    initialNotes(newNotes) {
      this.localNotes = newNotes.slice();
      this.editableRowId = null;
    },
  },
};

</script>

<style>
.hide-header .p-datatable-thead {
  display: none;
}
</style>
