<template>
  <div>
    <div class="w-full bg-white mt-6 p-5 pt-1 rounded  relative">
      <div
          v-show="isBusy"
          class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
      >
        <spinner :color="'text-black'" :size="10"/>
      </div>
      <div>
        <Galleria
            :value="imageFiles"
            :activeIndex.sync="activeIndex"
            :responsiveOptions="responsiveOptions"
            :numVisible="7"
            containerStyle="max-width: 850px"
            :circular="true"
            :fullScreen="true"
            :showItemNavigators="true"
            :showThumbnails="false"
            :visible.sync="displayCustom"
        >
          <template #item="slotProps">
            <img
                :src="slotProps.item.access_url"
                :alt="slotProps.item.name"
                style="width: 100%; display: block"
            />
          </template>
          <template #thumbnail="slotProps">
            <img
                :src="slotProps.item.access_url_thumbnail"
                :alt="slotProps.item.name"
                style="display: block"
            />
          </template>
        </Galleria>

        <div v-if="sortedFiles">
          <div class="flex flex-wrap gap-2 justify-start">
            <div
                v-for="(file, index) in sortedFiles"
                :key="index"
                class="relative"
                style="width: 100px; height: 100px"
            >
              <!-- Conditional rendering based on file type -->
              <img
                  v-if="isImage(file)"
                  :src="file.access_url_thumbnail"
                  :alt="file.name"
                  :class="imageClass(file)"
                  class="cursor-pointer"
                  @click="editMode ? null : imageClick(index)"
              />

              <!-- PDF Icon -->
              <div
                  @click="editMode ? null : pdfClick(file)"
                  v-else-if="isPDF(file)"
                  class="flex items-center justify-center border-4 border-orange-300 cursor-pointer"
                  style="width: 100px; height: 100px"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="none"
                    stroke="#ff2825"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icon-tabler-file-type-pdf"
                    viewBox="0 0 24 24"
                >
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                  <path
                      d="M5 12V5a2 2 0 0 1 2-2h7l5 5v4M5 18h1.5a1.5 1.5 0 0 0 0-3H5v6M17 18h2M20 15h-3v6M11 15v6h1a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-1z"
                  />
                </svg>
              </div>

              <!-- Document Icon -->
              <div
                  @click="editMode ? null : docClick(file)"
                  v-else
                  class="flex items-center justify-center border-4 border-orange-300 cursor-pointer"
                  style="width: 100px; height: 100px"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-file-type-doc"
                    width="60"
                    height="60"
                    viewBox="0 0 24 24"
                    stroke-width="1"
                    stroke="#00abfb"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                  <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4"/>
                  <path d="M5 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z"/>
                  <path d="M20 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0"/>
                  <path
                      d="M12.5 15a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1 -3 0v-3a1.5 1.5 0 0 1 1.5 -1.5z"
                  />
                </svg>
              </div>

              <!-- Checkbox for Edit Mode -->
              <div
                  v-if="editMode"
                  class="absolute bottom-0 left-0 right-0 flex justify-center"
                  style="padding-bottom: 5px"
              >
                <Checkbox v-model="selectedFiles" :value="file.unique_id"/>
              </div>
            </div>

            <!-- Trigger for File Upload with Custom SVG Icon -->
            <div
                v-if="canEdit"
                class="flex items-center justify-center border-4 border-grey-300 cursor-pointer"
                @click="addNewFile"
                style="width: 100px; height: 100px"
            >
              <!-- Custom SVG Icon -->
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  fill="none"
                  stroke="#ff9300"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icon-tabler-file-plus"
                  viewBox="0 0 24 24"
              >
                <path stroke="none" d="M0 0h24v24H0z"/>
                <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                <path
                    d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zM12 11v6M9 14h6"
                />
              </svg>
            </div>
          </div>
        </div>
        <!-- Hidden File Input -->
        <input
            type="file"
            multiple
            ref="fileInput"
            @change="handleFileSelection"
            class="hidden"
        />

        <!-- Upload and Cancel Buttons -->
        <div
            v-if="files.length && canEdit"
            class="mx-auto flex justify-end mt-2"
        >
          <AppButton v-if="!editMode" color="blue" label="Edit" @click="enableFileSelection" :icon=true class="mr-2">
            <template v-slot:icon>
              <Icon iconType="edit" class="mr-2"/>
            </template>
          </AppButton>
          <AppButton v-if="editMode" color="red" label="Delete" @click="deleteSelectedFiles" :icon=true class="mr-2"
                     :badge="getSelectedFilesCount" badgeType="danger">
            <template v-slot:icon>
              <Icon iconType="delete" class="mr-2"/>
            </template>
          </AppButton>
          <AppButton color="gray" label="Cancel" @click="cancelFileSelection" :icon=true class="mr-2">
            <template v-slot:icon>
              <Icon iconType="close" class="mr-2"/>
            </template>
          </AppButton>

          <AppButton :disabled="editMode" color="green" label="Upload" @click="uploadFiles" :icon=true class="mr-2"
                     :badge="getNewFilesCount" badgeType="success">
            <template v-slot:icon>
              <Icon iconType="upload" class="mr-2"/>
            </template>
          </AppButton>
        </div>
      </div>
    </div>

    <portal to="overlay-outlet">
      <ViewDocument
          :showing="viewingDocument.panelOpen"
          :document="viewingDocument.doc"
          @close="viewingDocument.panelOpen = false"
      />
    </portal>

  </div>
</template>

<script>
import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";
import Pica from "pica";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";

const ViewDocument = () => import("@/components/ViewDocument.vue");
export default {
  name: "ManageFiles",
  mixins: [notificationMixin],
  components: {
    Spinner,
    AppButton,
    Icon,
    ViewDocument,
    Panel
  },
  props: {
    uploadRefId: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checked: true,
      isBusy: false,
      editMode: false,
      selectedFiles: [],
      activeIndex: 0,
      displayCustom: false,
      responsiveOptions: [],
      viewingDocument: {
        doc: {},
        panelOpen: false,
      },
      files: [],
    };
  },

  async mounted() {
    if (this.uploadRefId) {
      await this.loadFiles();
    }
  },
  computed: {
    imageFiles() {
      // Filter out only image files from the sorted files
      return this.sortedFiles.filter((file) => this.isImage(file));
    },
    sortedFiles() {
      console.log("Recalculating sorted files");
      // Clone the files array to avoid modifying the original array
      const filesClone = [...this.files];

      // Define a custom sort function
      const sortFunction = (a, b) => {
        if (this.isImage(a) && !this.isImage(b)) return -1;
        if (!this.isImage(a) && this.isImage(b)) return 1;
        if (this.isPDF(a) && !this.isPDF(b)) return -1;
        if (!this.isPDF(a) && this.isPDF(b)) return 1;
        return 0;
      };

      // Sort the cloned array
      const sortedFiles = filesClone.sort(sortFunction);
      console.log(sortedFiles);
      return sortedFiles;
    },
    getNewFilesCount() {
      if (!this.files || this.files.length === 0) {
        return "0";
      }
      return this.files.filter((file) => file.isNew === true).length.toString();
    },
    getSelectedFilesCount() {
      if (!this.selectedFiles || this.selectedFiles.length === 0) {
        return "0";
      }
      return this.selectedFiles.length.toString();
    },
  },
  methods: {
    async loadFiles() {

      if (!this.uploadRefId)
        return false;

      const files = await this.QuotationService.getQuotationDocuments(
          this.uploadRefId,
          'attachment'
      );
      const filesWithUniqueId = files.map((file, index) => ({
        ...file,
        unique_id: this.generateUniqueId(),
      }));

      this.files = filesWithUniqueId;
    },
    enableFileSelection() {
      this.editMode = true;
      if (!this.editMode) {
        this.selectedFiles = [];
      }
    },
    cancelFileSelection() {
      this.editMode = false;
      this.selectedFiles = [];
    },
    async deleteSelectedFiles() {
      try {
        if (this.selectedFiles.length === 0) {
          this.notifyWarning("No files selected to delete.");
          return;
        }

        this.isBusy = true;

        const confirmed = await this.confirmAction({
          message: "Are you sure you want to delete the selected files?",
          // You can add more details to the message if necessary
        });

        // Only proceed if the action is confirmed
        if (!confirmed) {
          return;
        }

        const selectedFilesSet = new Set(this.selectedFiles);
        let filesDeleted = false;

        // Delete local files
        const localFilesToDelete = this.files.filter(
            (file) => file.isNew && selectedFilesSet.has(file.unique_id),
        );
        if (localFilesToDelete.length > 0) {
          this.files = this.files.filter(
              (file) => !selectedFilesSet.has(file.unique_id),
          );
          this.selectedFiles = this.selectedFiles.filter(
              (id) => !localFilesToDelete.some((file) => file.unique_id === id),
          );
          filesDeleted = true;
        }

        // Delete server files
        if (this.uploadRefId) {
          const serverFilesToDelete = this.files
              .filter((file) => !file.isNew && selectedFilesSet.has(file.unique_id))
              .map((file) => file.document_id);

          if (serverFilesToDelete.length > 0) {
            await this.QuotationService.deleteQuotationDocuments(
                this.uploadRefId,
                serverFilesToDelete,
            );
            this.selectedFiles = this.selectedFiles.filter(
                (unique_id) =>
                    !this.files.some(
                        (file) =>
                            file.document_id &&
                            serverFilesToDelete.includes(file.document_id) &&
                            file.unique_id === unique_id,
                    ),
            );

            filesDeleted = true;
          }
        }

        // Handling messages
        if (filesDeleted) {
          this.notifySuccess("Selected files removed successfully");
          await this.loadFiles();
          this.editMode = false;
        } else {
          this.notifyWarning("No documents selected for deletion");
        }
      } catch (error) {
        console.error("Failed to delete documents:", error);
        this.notifyError("Failed to delete documents");
      } finally {
        this.isBusy = false;
      }
    },
    imageClass(file) {
      const isSelected = this.selectedFiles.includes(file.unique_id);
      return [
        "w-full h-full object-cover",
        {
          "border-4 border-gray-500": this.editMode && !isSelected,
          "border-4 border-green-500": file.isNew,
          "border-4 border-red-500": isSelected,
        },
      ];
    },
    isImage(file) {
      return ["jpg", "jpeg", "png", "gif", "bmp"].includes(
          file.content_type.toLowerCase(),
      );
    },
    isImageType(file) {
      return ["jpg", "jpeg", "png", "gif", "bmp"].includes(
          file.toLowerCase(),
      );
    },
    isPDF(file) {
      return file.content_type.toLowerCase() === "pdf";
    },
    isDocument(file) {
      const docTypes = ["doc", "docx", "txt", "odt", "rtf"];
      return (
          !this.isImage(file) &&
          !this.isPDF(file) &&
          docTypes.includes(file.content_type.toLowerCase())
      );
    },
    getFileExtension(file) {
      return file.name
          ? file.name.split(".").pop()
          : file.access_url.split(".").pop();
    },
    addNewFile() {

      if (!this.uploadRefId) {
        this.notifyWarning("Cannot add new files before save this item.");
        return;
      }

      if (this.editMode) {
        this.notifyWarning("Cannot add new files while in edit mode.");
        return;
      }
      this.openFileUpload();
    },
    openFileUpload() {
      this.$refs.fileInput.click();
    },
    handleFileSelection() {
      const selectedFiles = Array.from(this.$refs.fileInput.files);
      selectedFiles.forEach((file) => {

        const fileUrl = URL.createObjectURL(file);
        const fileType = this.getFileExtension(file);
        const uniqueId = this.generateUniqueId();
        const requires_thumbnail = this.isImageType(fileType);
        this.files.push({
          access_url: fileUrl,
          access_url_thumbnail: fileUrl,
          name: file.name,
          isNew: true,
          content_type: fileType,
          unique_id: uniqueId,
          original_file: file,
          requires_thumbnail: requires_thumbnail,
        });
      });

      this.resetFileInput();
    },
    async uploadFiles() {
      console.log("Uploading", this.files);
      try {

        this.isBusy = true;
        const newFilesToUpload = this.files.filter((file) => file.isNew);

        if (!newFilesToUpload || newFilesToUpload.length === 0) {
          this.notifyWarning("No new files to upload.");
          return;
        }

        await this.uploadDocumentWithThumbnail(newFilesToUpload);
        await this.loadFiles();

      } catch (error) {
        console.error("Failed to save documents:", error);
        this.notifyError("Failed to save documents");
      } finally {
        this.isBusy = false;
      }

    },
    resetFileInput() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
    docClick(file) {
      const link = document.createElement('a');
      link.href = file.access_url;
      link.download = file.filename;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    },
    imageClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
    pdfClick(file) {

      if (file.isNew) return;

      this.viewingDocument = {
        doc: file,
        panelOpen: true,
      };
    },

    async uploadDocumentWithThumbnail(editingDoc) {
      this.isBusy = true;
      try {
        if (!this.uploadRefId) {
          this.notifyError(
              "Please save the Goods In Details before upload docs.",
          );
          return;
        }

        const uploadResults = await this.QuotationService.uploadDocuments(
            editingDoc,
            this.uploadRefId,
        );

        for (const uploadResult of uploadResults) {
          const fileToUpload = this.files.find(
              (file) => file.unique_id === uploadResult.unique_id,
          );
          if (fileToUpload) {

            // Upload the main document
            if (uploadResult.access_url) {
              await this.uploadFileToBlob(
                  uploadResult.access_url,
                  fileToUpload.original_file,
              );

            }

            // Upload the thumbnail, if applicable
            if (fileToUpload && uploadResult.access_url_thumbnail) {

              console.log("Original file size:", fileToUpload.size, "bytes");
              const thumbnailFile = await this.resizeImage(
                  fileToUpload.original_file,
              );
              console.log("Thumbnail size:", thumbnailFile.size, "bytes");

              await this.uploadFileToBlob(
                  uploadResult.access_url_thumbnail,
                  thumbnailFile,
              );
            }
          }
        }

        this.notifySuccess("Document and thumbnail uploaded successfully.");
      } catch (error) {
        console.error("Upload error:", error);
        this.notifyError("There was an error uploading the document.");
      } finally {
        this.isBusy = false;
      }
    },

    async uploadFileToBlob(sasUrl, file) {

      const url = new URL(sasUrl);
      const accountUrl = url.origin;
      const sasToken = url.search;
      const pathParts = url.pathname.split("/").filter((p) => p.trim() !== "");
      const container = pathParts[0];
      const filename = pathParts.slice(1).join("/");

      const blobService = AzureStorage.Blob.createBlobServiceWithSas(
          accountUrl,
          sasToken,
      );
      const customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 128;
      blobService.singleBlobPutThresholdInBytes = customBlockSize;

      return new Promise((resolve, reject) => {
        blobService.createBlockBlobFromBrowserFile(
            container,
            filename,
            file,
            {blockSize: customBlockSize},
            (error) => {
              if (error) {
                reject(error);
              } else {
                resolve();
              }
            },
        );
      });
    },

    generateUniqueId() {
      return `id_${Date.now()}_${Math.random().toString(36).slice(2, 11)}`;
    },


    async resizeImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const img = new Image();
          img.onload = async () => {
            const pica = Pica();

            // Define your maximum dimensions
            const maxWidth = 100;
            const maxHeight = 100;

            const canvas = document.createElement("canvas");
            const ratio = Math.min(
                maxWidth / img.width,
                maxHeight / img.height,
            );
            canvas.width = img.width * ratio;
            canvas.height = img.height * ratio;

            try {
              await pica.resize(img, canvas);
              canvas.toBlob(
                  (blob) => {
                    const resizedFile = new File([blob], `resized_${file.name}`, {
                      type: "image/jpeg", // Ensure this matches the format used in toBlob
                      lastModified: Date.now(),
                    });
                    resolve(resizedFile);
                  },
                  "image/jpeg", // This should match the MIME type of the resized file
                  0.9,
              );
            } catch (err) {
              reject(err);
            }
          };
          img.src = e.target.result;
        };
        reader.onerror = (err) => {
          reject(err);
        };
        reader.readAsDataURL(file);
      });
    },
  },
};
</script>

<style scoped>
.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
</style>