<template>
  <div>
    <!-- Default address -->
    <div v-if="contact && contact.address" class="flex items-center py-1">
      <svg class="text-orange-700 stroke-current h-5 w-5 mr-2" fill="none" stroke-linecap="round"
           stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z"/>
        <circle cx="12" cy="10" r="3"/>
      </svg>
      <p class="rounded-lg px-2 py-1">
        {{ formatDefaultAddress() }}
      </p>
    </div>
    <!-- Default address -->

    <div v-if=" contact && contact.people">
      <div v-if="contact.people.firstname || contact.people.lastname" class="flex items-center py-1">
        <svg class="text-orange-700 stroke-current h-5 w-5 mr-2" fill="none" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
          <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"/>
          <circle cx="12" cy="7" r="4"/>
        </svg>
        <p v-if="contact.people.job_title" class="bg-orange-200 text-orange-700 rounded-lg py-1 px-2">
          {{ contact.people.job_title }}
        </p>
        <p class="rounded-lg px-2 py-1">
          <span v-if="contact.people.firstname">{{ contact.people.firstname + " " }}</span>
          <span v-if="contact.people.lastname">{{ contact.people.lastname }}</span>
        </p>
      </div>
      <div v-if="contact.people.email" class="flex items-center py-1">
        <svg class="text-orange-700 stroke-current h-5 w-5 mr-2" fill="none" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
          <path d="M22 6l-10 7L2 6"/>
        </svg>
        <a class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
           :href="'mailto:' + contact.people.email">{{ contact.people.email }}</a>
      </div>
      <div v-if="contact.people.mobile || contact.people.telephone" class="flex items-center py-1">
        <svg class="text-orange-700 stroke-current h-5 w-5 mr-2" fill="none" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
          <path
              d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"/>
        </svg>
        <a v-if="contact.people.mobile" class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
           :href="'tel:' + contact.people.mobile">{{ contact.people.mobile }}</a>
        <a v-if="contact.people.telephone" class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
           :href="'tel:' + contact.people.telephone">{{ contact.people.telephone }}</a>
      </div>

      <div v-if="contact.website" class="flex items-center py-1">
        <svg class="text-orange-700 stroke-current h-5 w-5 mr-2" fill="none" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10"/>
          <path d="M2 12h20M12 2a15.3 15.3 0 014 10 15.3 15.3 0 01-4 10 15.3 15.3 0 01-4-10 15.3 15.3 0 014-10z"/>
        </svg>
        <a class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1" :href="'//' + contact.website" target="_blank">
          {{ contact.website }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      required: false,
      default: null
    }
  },
  methods: {
    formatDefaultAddress: function () {
      if (!this.contact.address) {
        return ``;
      }

      let address = this.contact.address;

      return [
        address.address1,
        address.address2,
        address.address3,
        address.city,
        address.postcode,
      ]
          .filter((part) => !!part)
          .join(", ");
    },
  }
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>
