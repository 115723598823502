<template>
  <div>
    <div id="editor-container" class="quill-editor"></div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  name: 'StyledTextEditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.setUpQuill();
  },
  methods: {
    formatHtml(html) {
      const tab = "\t";
      let result = "";
      let indent = "";

      html.split(/>\s*</).forEach(function (element) {
        if (element.match(/^\/\w/)) {
          indent = indent.substring(tab.length);
        }

        result += indent + "<" + element + ">\r\n";

        if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith("input")) {
          indent += tab;
        }
      });

      return result.substring(1, result.length - 3);
    },
    setUpIndent() {
      const Parchment = Quill.import("parchment");

      class IndentAttributor extends Parchment.Attributor.Style {
        add(node, value) {
          if (value === 0) {
            this.remove(node);
            return true;
          } else {
            return super.add(node, `${value}em`);
          }
        }
      }

      const IndentStyle = new IndentAttributor("indent", "text-indent", {
        scope: Parchment.Scope.BLOCK,
        whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
      });
      Quill.register(IndentStyle, true);
    },
    setDefaultMargins() {
      const Block = Quill.import("blots/block");

      class Pblock extends Block {
        static create(value) {
          const node = super.create();
          node.setAttribute(
              "style",
              "margin-top:0in;margin-right:0in;margin-bottom:5.0pt;margin-left:0in;line-height:107%;"
          );
          return node;
        }
      }

      Quill.register(Pblock, true);
    },
    customHeader() {
      const Block = Quill.import("formats/header");

      class Hblock extends Block {
        static create(value) {
          const node = super.create(value);
          node.setAttribute(
              "style",
              "margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:107%;"
          );
          return node;
        }

        static formats(node) {
          return this.tagName.indexOf(node.tagName) + 1;
        }
      }

      Hblock.blotName = 'header';
      Hblock.tagName = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
      Quill.register(Hblock, true);
    },
    setUpQuill() {
      const AlignStyle = Quill.import("attributors/style/align");
      Quill.register(AlignStyle, true);
      const BackgroundStyle = Quill.import("attributors/style/background");
      Quill.register(BackgroundStyle, true);
      const ColorStyle = Quill.import("attributors/style/color");
      Quill.register(ColorStyle, true);
      const DirectionStyle = Quill.import("attributors/style/direction");
      Quill.register(DirectionStyle, true);
      const FontStyle = Quill.import("attributors/style/font");
      Quill.register(FontStyle, true);
      FontStyle.whitelist = ['sans serif', 'serif', 'monospace'];
      const SizeStyle = Quill.import("attributors/style/size");
      SizeStyle.whitelist = ["12px", "16px", "18px", "32px"];
      Quill.register(SizeStyle, true);
      this.customHeader();
      this.setUpIndent();
      this.setDefaultMargins();

      const defaultToolbarOptions = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{header: 1}, {header: 2}], // custom button values
        [{list: "ordered"}, {list: "bullet"}],
        [{script: "sub"}, {script: "super"}], // superscript/subscript
        [{indent: "-1"}, {indent: "+1"}], // outdent/indent
        [{direction: "rtl"}], // text direction

        [{size: ["14px", "16px", "18px"]}],
        [{header: [1, 2, 3, 4, 5, 6, false]}],

        [{color: []}, {background: []}], // dropdown with defaults from theme
        [{font: []}],
        [{align: []}],

        ["clean"], // remove formatting button
        ["link", "image"]
      ];

      const options = {
        modules: {
          toolbar: this.options.toolbar || defaultToolbarOptions,
        },
        theme: "snow",
        readOnly: this.disabled
      };

      this.quill = new Quill("#editor-container", options);

      this.quill.on("text-change", () => {
        if (!this.disabled) {
          this.$emit('input', this.quill.root.innerHTML);
        }
      });

      if (this.value) {
        this.quill.root.innerHTML = this.value;
      }
    }
  },
  watch: {
    value(newValue) {
      if (this.quill && newValue !== this.quill.root.innerHTML) {
        this.quill.root.innerHTML = newValue;
      }
    },
    disabled(newValue) {
      if (this.quill) {
        this.quill.enable(!newValue);
      }
    }
  }
};
</script>

<style scoped>
.custom-quill-editor {
  display: flex;
  flex-direction: column;
}
</style>
