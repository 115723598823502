<template>
  <div class="card">
    <DataTable :value="emailQueues" dataKey="queue_id" :paginator="true" :rows="10" :totalRecords="totalRecords"
               :lazy="false">
      <Column v-for="col in columns" :key="col.field" :field="col.field" :header="col.header"
              :headerStyle="{ width: col.width }">
        <template #body="slotProps">
          <span v-if="col.type === 'timestamp'">{{ formatTimestamp(slotProps.data[col.field]) }}</span>
          <span v-else-if="col.type === 'recipients'">{{ formatRecipients(slotProps.data[col.field]) }}</span>
        </template>
      </Column>
      <Column headerStyle="{ width: '5%' }">
        <template #body="slotProps">
          <span v-if="slotProps.data.status === 'SENT'" class="text-green-500">&#x2714;</span>
        </template>
      </Column>

      <template #paginatorstart>
        <Button type="button" icon="pi pi-refresh" class="p-button-text" @click="loadEmailQueues"/>
      </template>

    </DataTable>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    searchTerm: {
      type: String,
      default: ''
    },
    referenceId: {
      type: String,
      default: ''
    },
    emailQueueId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      emailQueues: [],
      totalRecords: 0,
      columns: [
        {field: 'created_at', header: 'Created At', type: 'timestamp', width: '30%'},
        {field: 'updated_at', header: 'Sent At', type: 'timestamp', width: '30%'},
        {field: 'to', header: 'To', type: 'recipients', width: '35%'}
      ]
    };
  },
  watch: {
    searchTerm: 'loadEmailQueues',
    referenceId: 'loadEmailQueues',
    emailQueueId: 'loadEmailQueues'
  },
  methods: {
    async loadEmailQueues() {
      try {
        const params = {
          searchTerm: this.searchTerm,
          referenceId: this.referenceId,
          emailQueueId: this.emailQueueId,
          noPaging: true
        };

        const result = await this.EmailService.searchAllEmailQueues(new URLSearchParams(params).toString());
        this.emailQueues = result;
      } catch (error) {
        console.error('Failed to load email queues', error);
      }
    },
    formatTimestamp(unixTimestamp) {
      const momentDate = this.$moment.unix(unixTimestamp);

      if (!momentDate.isValid()) {
        return 'Sending...';
      }

      return momentDate.format('DD-MM-YYYY HH:mm');
    },
    formatRecipients(recipients) {
      return recipients.map(recipient => recipient.email).join(', ');
    }
  },
  mounted() {
    this.loadEmailQueues();
  }
};
</script>

<style scoped>
/* Add your component styles here */
</style>
