<template>
  <div class="card">
    <DataTable :value="loanEquipment" dataKey="equipment_id">
      <Column field="name" header="Name"></Column>
      <Column field="serial_number" header="Serial Number"></Column>
      <Column field="action" header="Action"></Column>
      <Column header="">
        <template #body="slotProps">
          <AppButton color="red" label=""
                     v-if="!slotProps.data.loan_id"
                     :icon="true"
                     class="mr-1"
                     @click="removeEquipment(slotProps.data)">
            <template v-slot:icon>
              <Icon iconType="delete"/>
            </template>
          </AppButton>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  components: {
    AppButton,
    Icon
  },
  mixins: [notificationMixin],
  props: {
    initialLoanEquipment: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loanEquipment: []
    };
  },
  mounted() {
    this.loanEquipment = this.initialLoanEquipment.slice();
  },
  watch: {
    initialLoanEquipment(newLoanEquipments) {
      this.loanEquipment = newLoanEquipments.slice();
    },
  },
  methods: {
    getCurrentLoanEquipments() {
      return this.loanEquipment
    },
    addEquipment(equipment) {
      if (!this.loanEquipment.some(eq => eq.equipment_id === equipment.equipment_id)) {
        this.loanEquipment.push(equipment);
        return true;
      } else {
        this.notifyWarning("This equipment is already added.");
        return false;
      }
    },
    removeEquipment(equipment) {
      this.loanEquipment = this.loanEquipment.filter(eq => eq.equipment_id !== equipment.equipment_id);
    }
  }
};
</script>

<style>
</style>
