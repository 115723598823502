<template>
  <div class="flex flex-wrap bg-white p-5 rounded w-full">
    <!-- Header Row -->
    <div class="flex justify-between items-center mb-2 w-full bg-gray-200 p-2 rounded">
      <div class="w-1/8 text-left">
        <label class="uppercase font-semibold text-sm">Qty</label>
      </div>
      <div class="w-2/8 text-left">
        <label class="uppercase font-semibold text-sm">Code</label>
      </div>
      <div class="w-5/8 text-left">
        <label class="uppercase font-semibold text-sm">
          Description</label>
      </div>
    </div>
    <!-- Data Row -->
    <div class="flex justify-between items-center mb-4 w-full border-b pb-2 px-2">
      <div class="w-1/8 text-left">
        <span>{{ orderItem.quantity }}</span>
      </div>
      <div class="w-2/8 text-left">
        <span>{{ orderItem.product_code }}</span>
      </div>
      <div class="w-5/8 text-left">
        <span>{{ orderItem.description }}</span>
      </div>
    </div>

    <!-- Received Items Component -->
    <div class="w-full py-2 mt-10">
      <ReceivedItems
          :initialReceivedItems="orderItem.received_items || []"
          :purchase_order_id="purchase_order_id"
          :purchase_item_id="orderItem.purchase_item_id || ''"
          :targetQuantity="convertToInt(orderItem.quantity)"
          @receivedItems="updateReceivedItems"
      ></ReceivedItems>
    </div>
  </div>
</template>


<script>
import Notification from "@/components/Notification.vue";
import notificationMixin from "@/mixins/notificationMixin";
import Icon from "@/components/Icon.vue";
import ReceivedItems from "@/views/HR/Purchases/Components/ReceivedItems.vue";


export default {
  name: "AddReceivedItem",
  mixins: [notificationMixin],
  components: {
    ReceivedItems,
    Icon,

  },
  props: {
    orderItem: {
      required: true,
      type: Object
    },
    purchase_order_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  async mounted() {

  },
  methods: {
    convertToInt(value) {
      return parseInt(value, 10);
    },
    updateReceivedItems(receivedItems) {

      // this.orderItem.received_items = receivedItems;
      this.$emit('update-received-items', receivedItems);
    },
  },
};
</script>
