<template>
  <div class="card">
    <DataTable :value="products" dataKey="product_id">
      <!-- Dynamic columns -->
      <Column v-for="col in columns" :key="col.field" :field="col.field" :header="col.header"
              :headerStyle="getHeaderStyle(col)" :headerClass="col.headerClass" :styles="{ 'text-align': col.align }">
        <!-- Custom content based on column type -->
        <template v-if="col.type === 'editable'" #body="slotProps">
          <InputText v-if="editableRowId === slotProps.data.product_id" v-model.number="slotProps.data[col.field]"
                     @input="updateTotal(slotProps.data)"/>
          <span v-else>
            {{ col.field === 'quantity' ? slotProps.data[col.field] : slotProps.data[col.field].toFixed(2) }}
          </span>
        </template>


        <template v-else-if="col.type === 'editable_price'" #body="slotProps">
          <InputNumber v-if="editableRowId === slotProps.data.product_id" inputClass="text-right"
                       v-model="slotProps.data[col.field]" mode="currency"
                       currency="GBP"
                       locale="en-GB"/>
          <span v-else>
            {{ formatCurrency(slotProps.data[col.field]) }}
          </span>
        </template>


        <template v-else-if="col.type === 'tax_info'" #body="slotProps">
          <div v-if="editableRowId === slotProps.data.product_id">
            <select class="w-full bge-input bge-select rounded" v-model="slotProps.data.tax_code"
                    @change="updateTaxRate(slotProps.data)">
              <option v-for="tax in slotProps.data.tax_codes" :key="tax.data_master_id" :value="tax.name">
                {{ tax.name }} ({{ tax.value }})
              </option>
            </select>
          </div>
          <div v-else>
            {{ slotProps.data.tax_code }} ({{ slotProps.data.tax_rate }})
          </div>
        </template>

        <template v-else-if="col.type === 'amount'" #body="slotProps">
          <span class="text-right">£{{ slotProps.data.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
        </template>

        <template v-else-if="col.type === 'total_with_tax'" #body="slotProps">
          <span class="text-right">£{{
              slotProps.data.total_with_tax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}</span>
        </template>

        <template v-else-if="col.type === 'actions'" #body="slotProps">
          <div class="flex justify-end items-center">
            <div @click="addPartDelivery(slotProps.data)" class="cursor-pointer mr-1"
                 :title="calculateReceivedCounts(slotProps.data)">
              <Knob :value="calculateReceivedCounts(slotProps.data)" :min="0"
                    :max="convertToInt(slotProps.data.quantity)" :size="35" valueColor="#4CAF50" :strokeWidth="10"
              />
            </div>

            <AppButton v-if="editableRowId === slotProps.data.product_id" color="blue" label="" :icon="true"
                       class="mr-1" @click="saveRow(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="save"/>
              </template>
            </AppButton>
            <AppButton v-if="editableRowId === slotProps.data.product_id && products.length !== 0" color="gray" label=""
                       :icon="true" class="mr-1" @click="cancelEdit(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="close"/>
              </template>
            </AppButton>
            <AppButton v-else color="blue" label="" :icon="true" class="mr-1"
                       @click="editRow(slotProps.data.product_id)">
              <template v-slot:icon>
                <Icon iconType="edit"/>
              </template>
            </AppButton>
            <AppButton color="red" label="" :icon="true" class="mr-1" @click="deleteRow(slotProps.data.product_id)">
              <template v-slot:icon>
                <Icon iconType="delete"/>
              </template>
            </AppButton>
          </div>
        </template>
      </Column>

      <template #empty>
        No Items.
      </template>

    </DataTable>
    <portal to="overlay-outlet">
      <panel :showing="addReceivedItemPanelOpen" @close="addReceivedItemPanelOpen = false" title="Set Part Delivery">
        <AddReceivedItem :orderItem="selectedOrderItem" @update-received-items="updateReceivedItems"
                         :purchase_order_id="purchase_order_id"/>
      </panel>
    </portal>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import Panel from "@/components/Panel.vue";
import AddReceivedItem from "@/views/HR/Purchases/Components/AddReceivedItem.vue";

export default {
  mixins: [notificationMixin],
  components: {
    AddReceivedItem,
    Panel,
    AppButton,
    Icon
  },
  props: {
    initialProducts: {
      type: Array,
      required: true,
    },
    purchase_order_id: {
      type: String,
    }
  },
  data() {
    return {
      value: 50,
      selectedOrderItem: null,
      selectedOrderItemIndex: -1,
      addReceivedItemPanelOpen: false,
      products: [],
      editableRowId: null,
      originalProductData: {},
      columns: [
        {field: 'quantity', header: 'Quantity', type: 'editable', width: '10%'},
        {field: 'product_code', header: 'Boston Code', width: '10%'},
        {field: 'supplier_product_code', header: 'Supplier Code', width: '10%'},
        {field: 'description', header: 'Description', type: 'editable_text', width: '18%'},
        {
          field: 'price', header: 'Unit Cost', type: 'editable_price', width: '10%', align: 'right',
          headerClass: 'column-h-text-right'
        },
        {
          field: '', header: '', type: '', width: '2%',
        },
        {
          field: 'tax_info', header: 'Tax Rate', type: 'tax_info', width: '10%',
        },
        {
          field: 'total', header: 'Total', type: 'amount', width: '10%', align: 'right',
          headerClass: 'column-h-text-right '
        },
        {
          field: 'total_with_tax',
          header: 'Total Value',
          type: 'total_with_tax',
          width: '10%',
          align: 'right',
          headerClass: 'column-h-text-right'
        },
        {header: '', type: 'actions', width: '10%'}
      ],
    };
  },
  watch: {
    initialProducts(newProducts) {
      this.products = newProducts.slice();
    },
  },
  async mounted() {
    this.products = this.initialProducts.slice();
  },
  computed: {
    totalAmount() {
      return this.products.reduce((sum, product) => sum + product.total, 0);
    }
  },
  methods: {
    async saveOrderItem(product) {
      // Prepare the product data for the database
      const dbProduct = {
        product_id: product.product_id,
        product_code: product.product_code,
        supplier_product_code: product.supplier_product_code,
        quantity: Number(product.quantity),
        unit_price: Number(product.price),
        tax_code: product.tax_code,
        tax_rate: product.tax_rate,
        total: Number(product.total),
        total_with_tax: Number(product.total_with_tax),
        description: product.description,
        received_items: product.received_items,
      };

      try {
        if (product.isNew) {
          const createdProduct = await this.PurchaseOrderService.createPurchaseItem(this.purchase_order_id, dbProduct);

          Object.assign(product, createdProduct);
        } else {
          if (product.purchase_item_id) {
            await this.PurchaseOrderService.updatePurchaseItem(this.purchase_order_id, product.purchase_item_id, dbProduct);
          }
        }
        this.notifySuccess(`Product ${product.isNew ? 'created' : 'updated'} successfully.`);
        product.isNew = false;
        this.$emit("changed");
      } catch (error) {
        this.notifyError(`Error ${product.isNew ? 'creating' : 'updating'} product.`);
        console.error(error);
        throw error;
      }
    },

    async deleteOrderItem(PurchaseItemId) {
      try {

        await this.PurchaseOrderService.deletePurchaseItem(this.purchase_order_id, PurchaseItemId);

        this.products = this.products.filter(p => p.purchase_item_id !== PurchaseItemId);
        this.notifySuccess("Product deleted successfully.");
        this.$emit("changed");
      } catch (error) {
        this.notifyError("Error deleting product.");
        console.error(error);
      }
    },
    convertToInt(value) {
      return parseInt(value, 10);
    },
    calculateReceivedCounts(data) {
      if (!data.received_items || data.received_items.length === 0) {
        return 0;
      }
      const totalReceived = data.received_items.reduce((total, item) => {
        return total + parseFloat(item.quantity_received || 0);
      }, 0);
      return totalReceived;
    },

    updateReceivedItems(receivedItems) {
      // if (this.selectedOrderItem) {
      //   const selectedOrderItemProductId = this.selectedOrderItem.product_id;
      //
      //   const index = this.products.findIndex(function (item) {
      //     const match = item.product_id === selectedOrderItemProductId;
      //     return match;
      //   });
      //   if (index !== -1) {
      //     this.$set(this.products, index, {
      //       ...this.products[index],
      //       received_items: receivedItems,
      //     });
      //   }
      // }
      this.$emit("changed");
    },
    async setAllReceivedItems() {
      const today = this.$moment().unix();

      for (const product of this.products) {
        const totalOrderedQuantity = product.quantity;
        const totalReceivedQuantity = product.received_items.reduce((sum, item) => sum + item.quantity_received, 0);
        const remainingQuantity = totalOrderedQuantity - totalReceivedQuantity;

        if (remainingQuantity > 0) {
          const newReceivedItem = {
            quantity_received: remainingQuantity,
            received_date: today,
            isNew: true,
          };

          product.received_items.push(newReceivedItem);

          // Save the new received item to the database
          const itemCopy = JSON.parse(JSON.stringify(newReceivedItem));
          try {
            const createdItem = await this.PurchaseOrderService.createReceivedItem(
                this.purchase_order_id,
                product.purchase_item_id,
                itemCopy
            );
            console.log('Received item saved:', createdItem);
          } catch (error) {
            console.error('Error saving received item:', error);
          }
        }
      }

      this.$emit('changed');
    },
    addPartDelivery(orderItem, index) {
      this.selectedOrderItemIndex = index;
      this.selectedOrderItem = orderItem;
      this.addReceivedItemPanelOpen = true;
    },
    getCurrentProducts() {
      return this.products;
    },
    async addProducts(productsArray) {
      for (const product of productsArray) {
        if (this.isDuplicate(product)) {
          this.notifyWarning(`Product with ID ${product.product_id} is already added to the parts used.`);
        } else {
          const defaultQuantity = 1;
          const quantity = (product.quantity !== undefined && product.quantity !== 0) ? product.quantity : defaultQuantity;

          const price = parseFloat(product.cost_price);
          const total = price * quantity;
          const total_with_tax = total + (total * parseFloat(product.tax_rate || 0));

          // Mark the product as new for saving
          const newProduct = {
            ...product,
            price: price,
            quantity: quantity,
            total: total,
            total_with_tax: total_with_tax,
            isNew: true
          };

          try {
            await this.saveOrderItem(newProduct);
            this.products.push(newProduct);
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
    isDuplicate(newProduct) {
      return this.products.some(existingProduct => existingProduct.product_id === newProduct.product_id);
    },
    editRow(rowId) {
      const product = this.products.find(p => p.product_id === rowId);
      if (product) {
        this.originalProductData[rowId] = JSON.parse(JSON.stringify(product));
      }
      this.editableRowId = rowId;
    },
    async saveRow(product) {
      if (!product.description || product.quantity === null || product.price === null) {
        this.notifyError("Fields cannot be empty.");
        return;
      }
      if (isNaN(product.quantity) || isNaN(product.price)) {
        this.notifyError("Quantity and Price must be numbers.");
        return;
      }
      product.total = product.quantity * product.price;
      product.total_with_tax = product.total + (product.total * product.tax_rate);

      await this.saveOrderItem(product);

      this.editableRowId = null;
      delete this.originalProductData[product.product_id];
    },
    cancelEdit(product) {
      if (product.isNew) {
        this.products = this.products.filter(p => p.product_id !== product.product_id);
      } else {
        const originalData = this.originalProductData[product.product_id];
        if (originalData) {
          const index = this.products.findIndex(p => p.product_id === product.product_id);
          if (index !== -1) {
            this.products.splice(index, 1, originalData);
          }
        }
      }
      this.editableRowId = null;
      delete this.originalProductData[product.product_id];
    },
    deleteRow(rowId) {

      const product = this.products.find(p => p.product_id === rowId);
      if (product && product.purchase_item_id) {
        this.deleteOrderItem(product.purchase_item_id);
      }
    },
    updateTotal(product) {
      product.total = product.quantity * product.price;
      product.total_with_tax = product.total + (product.total * product.tax_rate);
    },
    updateTaxRate(product) {
      const selectedTax = product.tax_codes.find(tax => tax.name === product.tax_code);
      if (selectedTax) {
        product.tax_rate = selectedTax.value;
        product.total_with_tax = product.total + (product.total * product.tax_rate);
      }
    },
    generateGUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    getHeaderStyle(col) {
      return {
        width: col.width,
        textAlign: col['header-align'] || 'left', // Default to 'left' if not specified
      };
    },
    formatCurrency(value) {
      return '£' + parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  }
};
</script>

<style scoped>
.total-amount {
  margin-top: 10px;
  font-weight: bold;
}

::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}
</style>
