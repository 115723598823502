<template>
  <div class="summary bg-white p-5 rounded">
    <!-- Net Total -->
    <div class="summary-item flex justify-between items-center mb-4">
      <label class="font-semibold text-right w-10/12">Net Total:</label>
      <span class="text-right w-2/12">{{ formatCurrency(netTotal) }}</span>
    </div>

    <!-- Duty -->
    <div class="summary-item flex justify-between items-center mb-4">
      <label class="font-semibold text-right w-10/12">Duty:</label>
      <div class="flex items-center justify-end w-2/12">
        <InputNumber class="w-1/2 " inputClass="text-right" v-model="duty" mode="currency" currency="GBP"
                     locale="en-GB" @input="onDutyChange"/>
      </div>
    </div>

    <!-- Shipping Cost -->
    <div class="summary-item flex justify-between items-center mb-4">
      <label class="font-semibold text-right w-10/12">Shipping Cost:</label>
      <div class="flex items-center justify-end w-2/12">
        <InputNumber class="w-1/2" inputClass="text-right" v-model="shippingCost" mode="currency" currency="GBP"
                     locale="en-GB" @input="onShippingCostChange"/>
      </div>
    </div>

    <!-- Shipping Tax Type -->
    <div class="summary-item flex justify-between items-center mb-4">
      <label class="font-semibold text-right w-10/12">Shipping Tax Rate:</label>

      <div class="flex items-center justify-end w-2/12">
        <Dropdown class="w-1/2" v-model="selectedTax"
                  :options="taxCodes"
                  optionLabel="name" placeholder="<Tax>" @change="onShippingTaxChange">

          <template #value="slotProps">
            <div v-if="slotProps.value">
              <span> {{ slotProps.value.name }} - {{ slotProps.value.value }}</span>
            </div>
            <span v-else>
            {{ slotProps.placeholder }}
        </span>
          </template>

          <template #option="slotProps">
            {{ slotProps.option.name }} - {{ slotProps.option.value }}
          </template>
        </Dropdown>
      </div>
    </div>

    <!-- Shipping Cost with Tax -->
    <div class="summary-item flex justify-between items-center mb-4">
      <label class="font-semibold text-right w-10/12">Shipping Inc. Tax:</label>
      <span class="text-right w-2/12">{{ formatCurrency(shippingCostWithTax) }}</span>
    </div>

    <!-- Tax Total -->
    <div class="summary-item flex justify-between items-center mb-4">
      <label class="font-semibold text-right w-10/12">Total Tax:</label>
      <span class="text-right w-2/12">{{ formatCurrency(taxTotal) }}</span>
    </div>

    <hr class="my-4"/>

    <!-- Total Value -->
    <div class="summary-item flex justify-between items-center mb-4">
      <label class="font-semibold text-right w-10/12">Total Value:</label>
      <span class="text-right w-2/12">{{ formatCurrency(finalValue) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
    initialDuty: {
      type: Number,
      default: 0
    },
    initialShippingCost: {
      type: Number,
      default: 0
    },
    initialShippingTaxCode: {
      type: String,
      default: ""
    },
    initialShippingTaxRate: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      duty: this.initialDuty,
      shippingCost: this.initialShippingCost,
      selectedTaxCode: this.initialShippingTaxCode,
      selectedTaxRate: this.initialShippingTaxRate,
      selectedTax: {},
      finalValue: 0.0,
      shippingCostWithTax: 0.0,
      taxCodes: [],
    };
  },
  watch: {
    initialDuty(newValue) {
      this.duty = newValue;
      this.updateFinalValue();
    },
    initialShippingCost(newValue) {
      this.shippingCost = newValue;
      this.updateShippingCostWithTax();
      this.updateFinalValue();
    },
    initialShippingTaxCode(newValue) {
      this.selectedTaxCode = newValue;
      this.updateTaxRate();
      this.updateShippingCostWithTax();
      this.updateFinalValue();
    },
    initialShippingTaxRate(newValue) {
      this.selectedTaxRate = parseFloat(newValue);
      this.updateShippingCostWithTax();
      this.updateFinalValue();
    },
    products: {
      handler: 'updateFinalValue',
      deep: true,
    },
    duty() {
      this.updateFinalValue();
      this.$emit('update-duty', this.duty);
    },
    shippingCost() {
      this.updateShippingCostWithTax();
      this.updateFinalValue();
    },
    selectedTaxRate() {
      this.updateShippingCostWithTax();
      this.updateFinalValue();
    }
  },
  computed: {
    netTotal() {
      return this.products.reduce((sum, product) => sum + (product.total_with_tax || 0), 0);
    },
    taxTotal() {
      const itemTaxes = this.products.reduce((sum, product) => sum + ((product.total || 0) * (product.tax_rate || 0)), 0);
      const shippingTax = (parseFloat(this.shippingCost) || 0) * (parseFloat(this.selectedTaxRate) || 0);
      return itemTaxes + shippingTax;
    }
  },
  methods: {
    async fetchTaxCodes() {
      try {
        const response = await this.DatamasterService.getDataMasters('?noPaging=true&type=PO_Tax_Code&is_active=true&orderBy=name&order=1');
        this.taxCodes = response.map(item => ({
          data_master_id: item.data_master_id,
          name: item.name,
          value: parseFloat(item.value) || 0,
        }));
        this.checkAndSetInitialTaxCode();
      } catch (error) {
        console.error('Error fetching tax codes:', error);
      }
    },
    updateFinalValue() {
      const netTotal = this.netTotal || 0;
      const duty = parseFloat(this.duty) || 0;
      const shippingCostWithTax = this.shippingCostWithTax || 0;
      this.finalValue = netTotal + duty + shippingCostWithTax;
    },
    updateShippingCostWithTax() {
      const shippingCost = parseFloat(this.shippingCost) || 0;
      const shippingTax = shippingCost * (parseFloat(this.selectedTaxRate) || 0);
      this.shippingCostWithTax = shippingCost + shippingTax;
    },
    onDutyChange(value) {
      this.duty = parseFloat(value) || 0;
      this.$emit('update-duty', this.duty);
    },
    onShippingCostChange(value) {

      this.shippingCost = parseFloat(value) || 0;
      this.updateShippingCostWithTax();
      this.$emit('update-shipping-cost', this.shippingCost);
    },
    onShippingTaxChange() {
      if (!this.selectedTax || !this.selectedTax.name) {
        return;
      }
      const currentSelectedTax = this.taxCodes.find(tax => tax.name === this.selectedTax.name);
      if (currentSelectedTax) {
        this.selectedTaxCode = currentSelectedTax.name;
        this.selectedTaxRate = parseFloat(currentSelectedTax.value);
        this.updateShippingCostWithTax();
        this.$emit('update-shipping-tax-code', currentSelectedTax.name);
        this.$emit('update-shipping-tax-rate', currentSelectedTax.value);
      }
    },
    checkAndSetInitialTaxCode() {

      const existingTaxCode = this.taxCodes.find(tax => tax.name === this.initialShippingTaxCode && tax.value == this.initialShippingTaxRate);
      if (!existingTaxCode && this.initialShippingTaxCode && this.initialShippingTaxRate) {

        const newCustomCode = {
          data_master_id: 'custom',
          name: this.initialShippingTaxCode,
          value: this.initialShippingTaxRate,
        }

        this.taxCodes.push({
          newCustomCode
        });
        this.selectedTaxCode = this.initialShippingTaxCode;
        this.selectedTaxRate = parseFloat(this.initialShippingTaxRate);

        this.selectedTax = newCustomCode;
      } else if (existingTaxCode) {
        this.selectedTaxCode = existingTaxCode.name;
        this.selectedTaxRate = parseFloat(existingTaxCode.value);
        this.selectedTax = existingTaxCode;
      }
      this.updateFinalValue();
    },
    updateTaxRate() {
      const existingTaxCode = this.taxCodes.find(tax => tax.name === this.initialShippingTaxCode);
      if (existingTaxCode) {
        this.selectedTaxCode = existingTaxCode.name;
        this.selectedTaxRate = parseFloat(existingTaxCode.value);
        this.selectedTax = existingTaxCode;
      } else {
        this.selectedTax = {};
      }

    },
    formatCurrency(value) {
      return '£' + parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
  mounted() {
    this.fetchTaxCodes();
  }
};
</script>

<style scoped>
.summary-input {
  width: 100px;
}
</style>
