<template>
  <div class="card">
    <DataTable
        :value="sourceProducts"
        dataKey="product_id"
        :paginator="true"
        :rows="10"
        :totalRecords="sourceProducts.length"
        :lazy="false"
    >
      <!-- Dynamic columns -->
      <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          :headerStyle="{ width: col.width }"
      >
        <!-- Custom Content for Action Column -->
        <template v-if="col.custom" #body="slotProps">
          <AppButton color="blue" label=""
                     :icon="true"
                     class="mr-1"
                     @click="emitProduct(slotProps.data)">
            <template v-slot:icon>
              <Icon iconType="copy-plus"/>
            </template>
          </AppButton>
        </template>
      </Column>
    </DataTable>
  </div>
</template>


<script>

import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  components: {
    AppButton,
    Icon
  },
  mixins: [notificationMixin],
  props: {
    searchTerm: String,
  },
  data() {
    return {
      sourceProducts: [],
      columns: [
        {field: 'product_code', header: 'Code', width: '15%'},
        {field: 'name', header: 'Name', width: '70%'},
        {field: 'cost_price', header: 'Price', width: '10%'},
        {header: 'Action', width: '5%', custom: true}
      ],
    };
  },

  watch: {
    searchTerm(newValue) {
      this.loadProducts(newValue);
    }
  },
  methods: {
    async loadProducts(searchTerm = '') {
      try {
        const products = await this.ProductService.getProducts(searchTerm);
        this.sourceProducts = products;
      } catch (error) {
        this.notifyError("Error loading products");
        console.error(error);
      }
    },
    emitProduct(product) {
      this.$emit('productSelected', product);
    },

  }
};
</script>

<style>
</style>
