<template>
  <div class="card">
    <DataTable
        :value="sourceProducts"
        dataKey="product_id"
        :paginator="true"
        :rows="10"
        :totalRecords="sourceProducts.length"
        :lazy="false">

      <!-- Dynamic columns -->
      <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          :headerStyle="getHeaderStyle(col)" :headerClass="col.headerClass" :styles="{ 'text-align': col.align }">
        <!-- Custom content for cost_price column -->
        <template v-if="col.field === 'cost_price'" #body="slotProps">
          £{{ slotProps.data[col.field].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
        </template>

        <!-- Custom Content for Action Column -->
        <template v-else-if="col.custom" #body="slotProps">
          <div
              :style="{ display: 'flex', justifyContent: col.align === 'center' ? 'center' : col.align === 'right' ? 'flex-end' : 'flex-start', width: '100%' }">
            <AppButton color="blue" label=""
                       :icon="true"
                       class="mr-1"
                       @click="emitProduct(slotProps.data)">
              <template v-slot:icon>
                <Icon iconType="copy-plus"/>
              </template>
            </AppButton>
          </div>
        </template>

        <!-- Default content for other columns -->
        <template v-else #body="slotProps">
          {{ slotProps.data[col.field] }}
        </template>
      </Column>
      <template #empty>
        No records found.
      </template>
    </DataTable>
  </div>
</template>


<script>

import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  components: {
    AppButton,
    Icon
  },
  mixins: [notificationMixin],
  props: {
    searchTerm: String,
  },
  data() {
    return {
      sourceProducts: [],
      columns: [
        {field: 'product_code', header: 'Code', width: '20%'},
        {field: 'name', header: 'Name', width: '55%'},
        {
          field: 'cost_price', header: 'Price', width: '15%', align: 'right',
          headerClass: 'column-h-text-right'
        },
        {
          header: 'Action', width: '10%', custom: true, align: 'center',
          headerClass: 'column-h-text-center'
        }
      ],
    };
  },

  watch: {
    searchTerm(newValue) {
      this.loadProducts(newValue);
    }
  },
  methods: {
    async loadProducts(searchTerm = '') {
      try {
        const products = await this.ProductService.getProducts(searchTerm);
        this.sourceProducts = products;
      } catch (error) {
        this.notifyError("Error loading products");
        console.error(error);
      }
    },
    emitProduct(product) {
      this.$emit('productSelected', product);
    },
    getHeaderStyle(col) {
      return {
        width: col.width,
        textAlign: col['align'] || 'left', // Default to 'left' if not specified
      };
    },

  }
};
</script>

<style scoped>

::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}
</style>
